import React, { useState, useEffect } from "react"
import Firebase from "./Firebase";
import { navigate } from "gatsby"
import { getFirestore, doc, getDoc } from "firebase/firestore"


export const FirebaseContext = React.createContext({
    firebase: new Firebase(),
    usuario: null,
    setUsuario: (usuario) => {},
    perfilUsuario: null,
    setPerfilUsuario: (perfilUsuario) => {},
});

export const FirebaseProvider = ({ children }) => {

  const [usuario, setUsuario] = useState(null);
  const [perfilUsuario, setPerfilUsuario] = useState(null);
  const [showChild, setShowChild] = useState(false);
  const [firebase, setFirebase] = useState(null);

    useEffect(() => {

        if (!firebase) {
            setFirebase(new Firebase())
            return;
        }
        if (firebase) {
            firebase.auth.onAuthStateChanged(function(user) {
                setUsuario(user)
                setShowChild(true)
            }); 
        }

    }, [firebase]);

    useEffect(() => {
        const userProfileUpdate = async () => {
            if (usuario!=null) {
                const userProfile = await getDoc(doc(getFirestore(), "users", usuario.uid))
                setPerfilUsuario(userProfile.data())
                setShowChild(true);
            } else {
                setPerfilUsuario(null)
            }
		};

        userProfileUpdate();
    }, [usuario]);

  if (!showChild) {
      return <div></div>;
  } else {

      return (
          <FirebaseContext.Provider
              value={{ firebase, usuario, setUsuario, perfilUsuario, setPerfilUsuario}}
          >
              {children}
          </FirebaseContext.Provider>
      );
  }
};