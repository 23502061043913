import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Firebase web config
const devConfig = {
  apiKey: "AIzaSyCs2kceRBk0YGausIJ_T1I5_dByE-eBuz4",
  authDomain: "startuponsale-dev.firebaseapp.com",
  projectId: "startuponsale-dev",
  storageBucket: "startuponsale-dev.appspot.com",
  messagingSenderId: "428846364465",
  appId: "1:428846364465:web:3174a31bbbc906a09a2210"
};

const prodConfig = {
  apiKey: "AIzaSyDitAgEWh-Lk9qo_8j6sHxRTR1-AEv9sOI",
  authDomain: "startuponsale-4719e.firebaseapp.com",
  projectId: "startuponsale-4719e",
  storageBucket: "startuponsale-4719e.appspot.com",
  messagingSenderId: "959625851310",
  appId: "1:959625851310:web:4f47b4dc862e2aa697d83f"
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    this.auth = firebase.auth();
    this.uiConfig = {
      signInFlow: 'popup',
      signInSuccessUrl: '/proyectos',
      signInOptions: [ firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    }
  }
}

export default Firebase;
